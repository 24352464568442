<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column flex-nowrap">

      <span>{{subject}}</span>
      <div
        class="ref-detail d-flex flex-row"
        v-if="innerOptions.showDetail && canAccountAndDivision"
      >
        <document-name-ref
          v-if="accountId"
          :id="accountId"
          :cache-type="cacheTypeAccount"
          icon-name="i.Accounts"
          :show-icon="false"
          mixed
        />
        <span
          class="mx-1"
          v-if="accountId && companyId"
        >-</span>
        <document-name-ref
          v-if="companyId"
          :id="companyId"
          :cache-type="cacheTypeCompany"
          :show-icon="false"
          icon-name="i.BusinessDivisions"
          mixed
        />
      </div>
      <slot name="doc-type" />

    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    canAccountAndDivision () {
      return this.accountId || this.companyId
    },
    subject () {
      return this.cache?.doc?.subject
    },
    accountId () {
      return this.cache?.doc?.accountId
    },
    companyId () {
      return this.cache?.doc?.companyId
    },
    cacheTypeAccount () {
      return ClientCache.CacheType.AccountRef
    },
    cacheTypeCompany () {
      return ClientCache.CacheType.BusinessDivisionRef
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    },
    innerOptions () {
      return {
        showDetail: this.options?.showDetail ?? false
      }
    }
  },
  methods: {
  },
  data () {
    return {
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    mixed: Boolean,
    options: Object,
    cacheType: Number,
    iconProps: Object,
    showIcon: Boolean,
    iconName: String
  }
}
</script>

<style lang="stylus" scoped></style>
